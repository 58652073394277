<template>
<div class="navbar noprint">
  <a class="menu-item" href="#" @click="select('')">Inicio</a>
  <a v-if="props.usuario.ctacte !== -1" class="menu-item" href="#" @click="select('NuevaDDJJ')">Nueva declaración jurada</a>
  <a v-if="props.usuario.ctacte !== -1" class="menu-item" href="#" @click="select('MisDDJJ')">Mis declaraciones presentadas</a>
  <a v-if="props.usuario.ctacte == -1" class="menu-item" href="#" @click="select('MisDDJJAdmin')">Ver Presentaciones</a>
  <a class="menu-item" href="#" @click="logout">Salir</a>
</div>
</template>
        
<script setup>
  import { defineProps, defineEmits, onMounted } from "vue";

  const props = defineProps({
    usuario: {
      type: Object,
      required: true,
    }
  });
  const emit = defineEmits(['logout','changed']);

  onMounted(async () => {
  })

  function logout() {
    localStorage.removeItem('token');
    emit('logout');
  }

  function select(menu) {
    emit('changed', menu);
  }

</script>
    
<style>
.navbar {
  width: 100%;
  background-color: lightgray;
  text-align: right;
  padding: 7px;
}
.menu-item {
  width: 100px;
  background-color: white;
  margin: 5px;
  padding: 7px;
  color: black;
  border-color: black;
}
@media print {
  .noprint {
    display: none;
  }
}
</style>