<template>

  <div class="main">
    <div v-if="props.usuario.ctacte !== -1" class="titulo">Mis Presentaciones</div>
    <div v-if="props.usuario.ctacte == -1" class="titulo">Presentaciones</div>

    <div class="filter-container">
      <label for="periodoFilter">Período:</label>
      <select id="periodoFilter" v-model="periodoFilter" @change="applyFilter">
        <option value="-1">TODOS</option>
        <option v-for="(periodo, index) in periodos" :key="index" :value="periodo">{{ periodo }}</option>
      </select>

      <label for="presentacionFilter">Presentación:</label>
      <select id="presentacionFilter" v-model="presentacionFilter" @change="applyFilter">
        <option value="-1">TODAS</option>
        <option value="0">ORIGINAL</option>
        <option value="1">RECTIFICATIVA</option>
      </select>

      <div>
        <button class="boton" @click="applyFilter">Buscar</button>
        <button class="boton" @click="clearFilters">Limpiar</button>
      </div>
      
    </div>

    <div class="scroll-container">
      <table class="new-grilla">
        <tr class="header-grilla">
          <td class="header-cell">Período</td>
          <td class="header-cell">Presentación</td>
          <td class="header-cell">Fecha de Presentación</td>
          <td class="header-cell"></td>
        </tr>

        <tr v-for="(d, i) in filteredData" :key="i">
          <td>{{ data[i].periodo }}</td>
          <td>{{ data[i].presentacion }}</td>
          <td>{{ formatDate(data[i].fechaCierre) }}</td>
          <td>
            <button class="boton-verddjj" @click="ver(data[i])">Ver Presentación</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";
import axios from 'axios';
import { url } from '../config.js';

const props = defineProps({
  usuario: {
    type: Object,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['onVerDDJJ']);

const loading = ref(false);
const error = ref("");
const data = ref([]);
const filteredData = ref([]);

const currentYear = new Date().getFullYear();
const periodos = Array.from({length: currentYear - 2018}, (_, index) => (currentYear - index).toString());
const periodoFilter = ref('-1');
const presentacionFilter = ref('-1');

onMounted(async () => {
  applyFilter();
});

async function applyFilter() {

  loading.value = true;
  error.value = '';
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + props.token
    };
    
    const payload = { periodo: periodoFilter.value, presentacion: presentacionFilter.value };
    let res = await axios.post(url + '/ddjj/leerPresentaciones/' + periodoFilter.value + '/' + presentacionFilter.value, payload, {headers: headers});

    data.value = res.data;
    filteredData.value = res.data;
    loading.value = false;
  } catch (e) {
    loading.value = false;
    error.value = 'Error: ' + e;
  }
}

function clearFilters() {
  periodoFilter.value = '-1';
  presentacionFilter.value = '-1';
  applyFilter();
}


function ver(ddjjRow) {
  emit('onVerDDJJ', ddjjRow);
}

function formatDate(dateString) {
  if (dateString == null) return '';
  const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  return new Date(dateString).toLocaleDateString('es-ES', options);
}

</script>

<style>
.main {
  color: white;
  padding: 10px;
}
.titulo {
  font-size: x-large;
  color: white;
  margin-top: 8px;
}

.boton-verddjj {
  border: 2px solid #000000;
  padding: 8px 16px;
  width: 170px;
  background-color: white;
  color: black !important;
}

.scroll-container {
  max-height: 100%;
  overflow-y: auto;
}

.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.header-cell {
  white-space: pre-wrap;
  width: 100px;
  text-align: center;
}
.filter-container {
  margin-bottom: 10px;
}
.new-grilla {
  width: 50%;
}
.boton {
  padding: 5px;
  margin: 10px;
  width: 170px;
  color: black;
}

</style>

