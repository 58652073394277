<template>
  <div class="main">

      <table class="grilla">
        <tr class="header-grilla">
          <td>MES</td>
          <td>Rubro</td>
          <td>Alícuota</td>
          <td>Ingresos</td>
          <td>Ingresos Mensuales</td>
        </tr>
        
        <tr v-for="(d, i) in data.tasas_rubros" :key="i">
          <td v-if="shouldShowMes(i)">{{ data.tasas_rubros[i].mes }}</td>
          <td v-if="!shouldShowMes(i)"></td>
          <td>{{data.tasas_rubros[i].rubro}}</td>
          <td><input type="number" v-model="data.tasas_rubros[i].alicuota" required :disabled="props.disabled" @input="updateData" :class="{ 'number-input': true, 'input--error':data.tasas_rubros[i].alicuota == null || data.tasas_rubros[i].alicuota===''}"></td>
          <td><input type="number" v-model="data.tasas_rubros[i].ingresos" :disabled="props.disabled" @input="updateData" :class="{'number-input': true, 'input--error':data.tasas_rubros[i].ingresos == null || data.tasas_rubros[i].ingresos===''}"></td>
          <td v-if="shouldShowMes(i)">{{ getMonthlyIncomes(data.tasas_rubros[i].mes) }}</td>
        </tr>

        <tr class="totales">
          <td></td>
          <td></td>
           <td></td>
          <td style="text-align: right; padding-right: 17px;">{{ totalIngresos }}</td>
          <td></td>
        </tr>

      </table>
  </div>
</template>

<script setup>
  import { defineProps, onMounted, ref, defineEmits, computed } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change']);

  const data = ref(props.initialData);

  onMounted(async () => {
  })

  const updateData = () => {
    emit('change', data);
  };

  const totalIngresos = computed(() => {
    return data.value.tasas_rubros.reduce((acc, item) => {
      const value = item.ingresos !== null && item.ingresos !== '' ? parseFloat(item.ingresos) : 0;
      return acc + value;
    }, 0).toFixed(2);
  });

  const shouldShowMes = (index) => {
    return index === 0 || data.value.tasas_rubros[index].mes !== data.value.tasas_rubros[index - 1].mes;
  };

  const monthlyIncomes = computed(() => {
    const monthlyIncomesMap = {};
    data.value.tasas_rubros.forEach(item => {
      console.log(item.ingresos + ' | ' + parseFloat(item.ingresos));
      const value = item.ingresos !== null && item.ingresos !== '' ? parseFloat(item.ingresos) : 0;
      monthlyIncomesMap[item.mes] = (monthlyIncomesMap[item.mes] || 0) + value;
    });
    return monthlyIncomesMap;
  });

  const getMonthlyIncomes = (mes) => {
    return monthlyIncomes.value[mes].toFixed(2) || 0;
  };

</script>

<style scoped>
.number-input {
  background-color: white;
  width: 150px;
  text-align: right;
}
.input--error{
    border-color:red;
    border-style: solid;
    text-align: right;
}

.main {
  color: black;
  display: grid;
  place-items: center;
}
input {
  background-color: white;
  width: 140px;
  padding: 2px;
}
.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.grilla {
  padding: 5px;
}
.totales {
  background-color: lightgrey;
  color: black;
}
.presentado input {
  background-color: transparent;
  border: none;
  pointer-events: none;
}
.input--error{
    border-color:red;
    border-style: solid;
    text-align: right;
}
@media print {
  table.grilla {
    border-collapse: collapse; /* Ensures borders don't double up */
  }
  table.grilla td, table.grilla th {
    border: 1px solid black; /* Adds border to rows and columns */
    padding: 8px; /* Adds padding to cells for better readability */
    text-align: left; /* Optional: aligns text to the left */
  }
}

</style>
