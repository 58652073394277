<template>

<div class="main">

    <div>
      ¿Posee sucursales?
      <select v-model="data.posee_sucursales" :disabled="props.disabled" @change="updateData">
      <option>NO</option>
      <option>SI</option>
      </select>
    </div>
    <br>

    <div class="main" v-if="data.posee_sucursales == 'SI'">

      <table class="grilla">
        <tr class="header-grilla">
          <td>Calle</td>
          <td>Número</td>
          <td>Localidad</td>
          <td>Partido</td>
        </tr>
        
        <tr v-for="(d,i) in data.sucursales" v-bind:key="d.orden">
          <td>
            <div v-show="!props.disabled"><input type="text" :class="{'text-input': true}" v-model="data.sucursales[i].calle" :disabled="props.disabled" @input="updateData"></div>
            <div v-show="props.disabled">{{data.sucursales[i].calle}}</div>
          </td>
          <td>
            <div v-show="!props.disabled"><input type="number" v-model="data.sucursales[i].numero" :disabled="props.disabled" @input="updateData"></div>
            <div v-show="props.disabled">{{data.sucursales[i].numero}}</div>
          </td>
          <td>
            <div v-show="!props.disabled"><input type="text" v-model="data.sucursales[i].localidad" :disabled="props.disabled" @input="updateData"></div>
            <div v-show="props.disabled">{{data.sucursales[i].localidad}}</div>
          </td>
          <td>
            <div v-show="!props.disabled"><input type="text" v-model="data.sucursales[i].partido" :disabled="props.disabled" @input="updateData"></div>
            <div v-show="props.disabled">{{data.sucursales[i].partido}}</div>
          </td>
        </tr>
      </table>

    </div>
  
  </div>

</template>

<script setup>
  import { defineProps, onMounted, ref, defineEmits } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change']);

  const data = ref(props.initialData);

  onMounted(async () => {
    console.log(props.initialData);
  })

  const updateData = () => {
    emit('change', data);
  };

</script>

<style scoped>
.main {
  color: black;
  display: grid;
  place-items: center;
}
input {
  background-color: white;
}
.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.grilla {
  padding: 5px;;
}
.presentado input {
  background-color: transparent;
  border: none;
  pointer-events: none;
}
.text-input {
  background-color: white;
  width: 400px;
  text-align: left;
}
@media print {
  table.grilla {
    border-collapse: collapse; /* Ensures borders don't double up */
  }
  table.grilla td, table.grilla th {
    border: 1px solid black; /* Adds border to rows and columns */
    padding: 8px; /* Adds padding to cells for better readability */
    text-align: left; /* Optional: aligns text to the left */
  }
}

</style>
