<template>
  <header>
    <div class="header-left">
      <img src="./assets/logo.png" alt="Logo" class="logo" height="87px">
      <span v-if="ambiente=='TEST'" class="legend">AMBIENTE DE TESTEOS</span>
    </div>
    <div class="header-right" v-if="loggedIn">
      <span class="user-info" v-if="usuario.ctacte === -1">{{ usuario.sub }}</span>
      <span class="user-info" v-else>{{ usuario.sub }} - {{ usuario.descr }} ({{ usuario.ctacte }})</span>
    </div>
  </header>

  <div v-if="!loggedIn">
      <LoginPage @loginSuccess="handleLoginSuccess"></LoginPage>
    </div>
    <div v-else>
      <NavBar @logout="logout" v-bind:usuario="usuario" @changed='changeMenu'></NavBar>
      <HomePage v-if="menu == ''" v-bind:usuario="usuario" />
      <NuevaDDJJ v-if="menu == 'NuevaDDJJ'" v-bind:usuario="usuario" v-bind:token="token" @onNuevaDDJJ="onNuevaDDJJ"/>
      <MisDDJJ v-if="menu == 'MisDDJJ'" v-bind:usuario="usuario" v-bind:token="token" @onVerDDJJ="onVerDDJJ"/>
      <MisDDJJAdmin v-if="menu == 'MisDDJJAdmin'" v-bind:usuario="usuario" v-bind:token="token" @onVerDDJJ="onVerDDJJ"/>
      <DDJJ v-if="menu == 'DDJJ'" v-bind:usuario="usuario" v-bind:token="token" v-bind:ddjj="ddjj" @onVerMisDDJJ="onVerMisDDJJ"/>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import jwt_decode from "jwt-decode";
import LoginPage from './components/LoginPage.vue'
import DDJJ from './components/DDJJ.vue'
import NavBar from './components/NavBar.vue'
import HomePage from './components/HomePage.vue'
import NuevaDDJJ from './components/NuevaDDJJ.vue'
import MisDDJJ from './components/MisDDJJ.vue'
import MisDDJJAdmin from './components/MisDDJJAdmin.vue'
import { ambiente } from './config.js';

let loggedIn = ref(false);
let menu = ref('');
let usuario = ref(null);
let token = ref(null);
let ddjj = ref(null);

onMounted(async () => {
  if (localStorage.getItem('token')) {
    token.value = JSON.parse(localStorage.getItem('token')).access_token;
    usuario.value = jwt_decode(token.value);
    //console.log(usuario);
    loggedIn.value = true;
  }
})

function handleLoginSuccess(access_token) {
  usuario.value = jwt_decode(access_token);
  token.value = access_token;
  localStorage.setItem('token', JSON.stringify({access_token: access_token}));
  loggedIn.value = true;
}

function logout() {
  loggedIn.value = false;
  menu.value = '';
}
function changeMenu(menuSelected) {
  console.log(menuSelected);
  menu.value = menuSelected;
}

function onNuevaDDJJ(nuevaddjj) {
  ddjj.value = nuevaddjj;
  menu.value = 'DDJJ';
}

function onVerDDJJ(verddjj) {
  ddjj.value = verddjj;
  menu.value = 'DDJJ';
}

function onVerMisDDJJ() {
  if(usuario.value.ctacte > 0) {
    menu.value = 'MisDDJJ';
  } else {
    menu.value = 'MisDDJJAdmin';
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background-color: darkgreen;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.header-left {
  text-align: left;
}
.header-right {
  text-align: right;
}
.user-info {
  color: white;
  font-weight: bold;
}
.legend {
    font-size: 18px;
    color: red;
    margin-left: 10px; 
  }
</style>
