<template>
  <div class="main">

    <table class="grilla">
      <tr class="header-grilla">
        <td>MES</td>
        <td>Fecha de Pago</td>
        <td style="width: 100px;">N° de Comprobante</td>
        <td>Importe</td>
      </tr>
      
      <tr v-for="(d,i) in data" v-bind:key="d.orden">
        <td>{{data[i].mes}}</td>
        <td><input type="date" style="width: 140px;" v-model="data[i].fecha_de_pago" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="text" v-model="data[i].nro_de_comprobante" :disabled="props.disabled" :class="{'number-input': true}" @input="updateData"></td>
        <td><input type="number" v-model="data[i].importe" :disabled="props.disabled" :class="{'number-input': true}" @input="updateData"></td>
      </tr>

      <tr class="totales">
        <td></td>
        <td></td>
        <td></td>
        <td style="text-align: right; padding-right: 17px;">{{ totalImporte }}</td>
      </tr>
    </table>

  </div>
</template>

<script setup>
  import { defineProps, onMounted, ref, defineEmits, computed } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change']);

  const data = ref(props.initialData);

  onMounted(async () => {
    console.log(props.initialData);
  })

  const updateData = () => {
    emit('change', data);
  };

  const totalImporte = computed(() => {
    return data.value.reduce((acc, item) => {
      const value = item.importe !== null && item.importe !== '' ? parseFloat(item.importe) : 0;
      return acc + value;
    }, 0).toFixed(2);
  });


</script>

<style scoped>
.main {
  color: black;
  display: grid;
  place-items: center;
}
input {
  background-color: white;
  width: 100px;
  text-align: right;
}
.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.grilla {
  padding: 5px;;
}
.totales {
  background-color: lightgrey;
  color: black;
}
.presentado input {
  background-color: transparent;
  border: none;
  pointer-events: none;
}
.number-input {
  background-color: white;
  width: 150px;
  text-align: right;
}
.input--error{
    border-color:red;
    border-style: solid;
    text-align: right;
}
@media print {
  table.grilla {
    border-collapse: collapse; /* Ensures borders don't double up */
  }
  table.grilla td, table.grilla th {
    border: 1px solid black; /* Adds border to rows and columns */
    padding: 8px; /* Adds padding to cells for better readability */
    text-align: left; /* Optional: aligns text to the left */
  }
}

</style>
