<template>
  <div class="main">

    <div class="aviso">
    *De uso exclusivo para los contribuyentes inscriptos en Ingresos Brutos bajo el régimen de Convenio Multilateral
    <br>
    *completar según lo establecido en el Art.4.4 inciso k de la Ordenanza Fiscal y los Arts.2 y 35 del Régimen General, Arts.48/56 del Título VII Municipios C.M.
    </div>

    <br>
    <div>
      Inscripto en el régimen de Convenio Multilateral
      <select v-model="data.inscripto_en_regimen" :disabled="props.disabled" @change="updateData">
      <option>NO</option>
      <option>SI</option>
      </select>
      &nbsp;&nbsp;&nbsp;<span v-if="data.inscripto_en_regimen == 'SI'">Nro de Inscripción<input type="text" v-model="data.nro_inscripcion" :disabled="props.disabled" @input="updateData"></span>
    </div>
    <br>

    <div class="main" v-if="data.inscripto_en_regimen == 'SI'">
    
      <div class="aviso">
        Declaro que <select v-model="data.desarrollo_actividad_otras_jurisdicciones" :disabled="props.disabled" @change="updateData">
      <option>NO</option>
      <option>SI</option>
      </select> desarrollo actividad comercial en otras jurisdicciones dentro de la Pcia de Buenos Aires y los coeficientes a utilizar para la distribución de la base imponible de la Pcia de Buenos Aires en el ejercicio {{data.periodo}}, para el cálculo de la Tasa por Inspección de Seguridad e Higiene (Cap.IV) según el siguiente cuadro
      </div>

      <table v-if="data.desarrollo_actividad_otras_jurisdicciones == 'SI'" class="grilla">
      <tr class="header-grilla">
        <td>Jurisdicción</td>
        <td>Ingresos</td>
        <td>Coeficiente</td>
        <td>Gastos</td>
        <td>Coeficiente</td>
        <td style="width: 100px;">Coeficiente de Aplicación</td>
      </tr>
      
      <tr v-for="(d,i) in data.coeficientes_distribucion" v-bind:key="d.orden">
        <td>
          <div v-show="!props.disabled"><input type="text" v-model="data.coeficientes_distribucion[i].jurisdiccion" :class="{'text-input': true, 'input--error':i==0 && (data.coeficientes_distribucion[i].jurisdiccion == null || data.coeficientes_distribucion[i].jurisdiccion==='')}" :disabled="props.disabled" @input="updateData"></div>
          <div v-show="props.disabled">{{data.coeficientes_distribucion[i].jurisdiccion}}</div>
        </td>
        <td><input type="number" v-model="data.coeficientes_distribucion[i].ingresos" :class="{'number-input': true, 'input--error':data.coeficientes_distribucion[i].jurisdiccion !='' &&  (data.coeficientes_distribucion[i].ingresos == null || data.coeficientes_distribucion[i].ingresos==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data.coeficientes_distribucion[i].coeficiente_ingresos" :class="{'number-input': true, 'input--error':data.coeficientes_distribucion[i].jurisdiccion !='' &&  (data.coeficientes_distribucion[i].coeficiente_ingresos == null || data.coeficientes_distribucion[i].coeficiente_ingresos==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data.coeficientes_distribucion[i].gastos" :class="{'number-input': true, 'input--error':data.coeficientes_distribucion[i].jurisdiccion !='' &&  (data.coeficientes_distribucion[i].gastos == null || data.coeficientes_distribucion[i].gastos==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data.coeficientes_distribucion[i].coeficiente_gastos" :class="{'number-input': true, 'input--error':data.coeficientes_distribucion[i].jurisdiccion !='' &&  (data.coeficientes_distribucion[i].coeficiente_gastos == null || data.coeficientes_distribucion[i].coeficiente_gastos==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data.coeficientes_distribucion[i].coeficiente_aplicacion" :class="{'number-input': true, 'input--error':data.coeficientes_distribucion[i].jurisdiccion !='' &&  (data.coeficientes_distribucion[i].coeficiente_aplicacion == null || data.coeficientes_distribucion[i].coeficiente_aplicacion==='')}" :disabled="props.disabled" @input="updateData"></td>
      </tr>

      <tr class="totales">
        <td></td>
        <td style="text-align: right; padding-right: 17px;"> {{ totalIngresos }}</td>
        <td></td>
        <td style="text-align: right; padding-right: 17px;"> {{ totalGastos }}</td>
        <td></td>
        <td></td>
      </tr>
    </table>

    </div>
  
  </div>
</template>

<script setup>
  import { defineProps, onMounted, ref, defineEmits, computed } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change']);

  const data = ref(props.initialData);

  onMounted(async () => {
    console.log(props.initialData);
  })

  const updateData = () => {
    emit('change', data);
  };

  const totalIngresos = computed(() => {
    return data.value.coeficientes_distribucion.reduce((acc, item) => {
      const value = item.ingresos !== null && item.ingresos !== '' ? parseFloat(item.ingresos) : 0;
      return acc + value;
    }, 0).toFixed(2);
  });

  const totalGastos = computed(() => {
    return data.value.coeficientes_distribucion.reduce((acc, item) => {
      const value = item.gastos !== null && item.gastos !== '' ? parseFloat(item.gastos) : 0;
      return acc + value;
    }, 0).toFixed(2);
  });

</script>

<style scoped>
.main {
  color: black;
  display: grid;
  place-items: center;
}
input {
  background-color: white;
}
select {
  background-color: white;
}
.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.grilla {
  padding: 5px;;
}
.aviso {
  width: 80%;
  background-color: cadetblue;
}
.totales {
  background-color: lightgrey;
  color: black;
}
.presentado input {
  background-color: transparent;
  border: none;
  pointer-events: none;
}
.number-input {
  background-color: white;
  width: 150px;
  text-align: right;
}
.text-input {
  background-color: white;
  width: 300px;
  text-align: left;
}
.input--error{
    border-color:red;
    border-style: solid;
    text-align: right;
}
@media print {
  table.grilla {
    border-collapse: collapse; /* Ensures borders don't double up */
  }
  table.grilla td, table.grilla th {
    border: 1px solid black; /* Adds border to rows and columns */
    padding: 8px; /* Adds padding to cells for better readability */
    text-align: left; /* Optional: aligns text to the left */
  }
}

</style>
