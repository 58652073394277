<template>
  <div class="main">
    <div class="titulo">Domicilio de habilitación</div>
    <table width="80%">
      <tr>
        <td>CALLE</td>
        <td colspan="6"><input class="input3" type="text" v-model="data.domicilio_habilitacion.calle" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_habilitacion.calle == null || data.domicilio_habilitacion.calle===''}"></td>
      </tr>
      <tr>
        <td>NUMERO</td>
        <td><input type="number" v-model="data.domicilio_habilitacion.numero" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_habilitacion.numero == null || data.domicilio_habilitacion.numero===''}"></td>
        <td>PISO</td>
        <td><input type="number" v-model="data.domicilio_habilitacion.piso" :disabled="props.disabled" @input="updateData"></td>
        <td>DEPTO</td>
        <td><input type="text" v-model="data.domicilio_habilitacion.dpto" :disabled="props.disabled" @input="updateData"></td>
      </tr>
      <tr>
        <td>COD.POSTAL</td>
        <td><input type="text" v-model="data.domicilio_habilitacion.cod_postal" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_habilitacion.cod_postal == null || data.domicilio_habilitacion.cod_postal===''}"></td>
        <td>LOCALIDAD</td>
        <td><input class="input2" type="text" v-model="data.domicilio_habilitacion.localidad" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_habilitacion.localidad == null || data.domicilio_habilitacion.localidad===''}"></td>
        <td>PARTIDO</td>
        <td><input type="text" v-model="data.domicilio_habilitacion.partido" :disabled="props.disabled" @input="updateData"></td>
      </tr>
    </table>

    <br>
    <div class="titulo">Domicilio Fiscal/Administrativo o Sede Central</div>
    <table width="80%">
      <tr>
        <td>CALLE</td>
        <td colspan="6"><input class="input3" type="text" v-model="data.domicilio_fiscal.calle" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_fiscal.calle == null || data.domicilio_fiscal.calle===''}"></td>
      </tr>
      <tr>
        <td>NUMERO</td>
        <td><input type="number" v-model="data.domicilio_fiscal.numero" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_fiscal.numero == null || data.domicilio_fiscal.numero===''}"></td>
        <td>PISO</td>
        <td><input type="number" v-model="data.domicilio_fiscal.piso" :disabled="props.disabled" @input="updateData"></td>
        <td>LOCAL</td>
        <td><input class="input2" type="text" v-model="data.domicilio_fiscal.local" :disabled="props.disabled" @input="updateData"></td>
      </tr>
      <tr>
        <td>DEPTO</td>
        <td><input type="text" v-model="data.domicilio_fiscal.dpto" :disabled="props.disabled" @input="updateData"></td>
        <td>COD.POSTAL</td>
        <td><input type="text" v-model="data.domicilio_fiscal.cod_postal" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_fiscal.cod_postal == null || data.domicilio_fiscal.cod_postal===''}"></td>
        <td>LOCALIDAD</td>
        <td><input class="input2" type="text" v-model="data.domicilio_fiscal.localidad" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_fiscal.localidad == null || data.domicilio_fiscal.localidad===''}"></td>
      </tr>
      <tr>
        <td>TELEFONO</td>
        <td><input type="text" v-model="data.domicilio_fiscal.telefono" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_fiscal.telefono == null || data.domicilio_fiscal.telefono===''}"></td>
        <td>EMAIL</td>
        <td colspan="3"><input type="text" class="input2" v-model="data.domicilio_fiscal.email" :disabled="props.disabled" @input="updateData" :class="{'input--error':data.domicilio_fiscal.email == null || data.domicilio_fiscal.email===''}"></td>
      </tr>
    </table>
  </div>
</template>

<script setup>
  import { defineProps, ref, defineEmits } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change']);

  const data = ref(props.initialData);

  const updateData = () => {
    emit('change', data);
  };

</script>

<style scoped>
.main {
  color: black;
  text-align: left;
}
input {
  background-color: white;
}
.input2 {
  background-color: white;
  width: 400px;
}
.input3 {
  background-color: white;
  width: 100%;
}
.titulo {
  color: black;
  font-size: 20px;
}
.presentado input {
  background-color: transparent;
  border: none;
  pointer-events: none;
}
.input--error{
    border-color:red;
    border-style: solid;
    text-align: right;
}
</style>
