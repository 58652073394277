<template>
<div>
  <div v-if="loading" class="loading">
    <v-progress-circular
      indeterminate
      color="primary">
    </v-progress-circular>
  </div>
  <div v-if="!loading">
    <HeaderDDJJ :initial-data="header" @change="headerChange" />
    <br>
    <v-alert type="error" v-if="error != ''">
      {{ error }}
    </v-alert>

    <div v-if="vista=='carga'">
      <v-card>
        <v-tabs
          v-model="tab"
          bg-color="white"
          show-arrows
        >
          <v-tab value="0">Datos del Contribuyente</v-tab>
          <v-tab value="1">Ingresos Mensuales</v-tab>
          <v-tab value="2">Determinacion de la Tasa</v-tab>
          <v-tab value="3">Convenio Multilateral</v-tab>
          <v-tab value="4">Pagos Directos de Tasas</v-tab>
          <v-tab value="5">Determinacion de Diferencias</v-tab>
          <v-tab value="6">Actividades Desarrolladas</v-tab>
          <v-tab value="7">Sucursales o Filiales</v-tab>
          <v-tab value="8">Tributo Municipal por la Propiedad</v-tab>      
        </v-tabs>

        <v-card-text style="padding: 0.3rem;">
          <v-window v-model="tab" class="custom-window" >
            <v-window-item value="0" >
              <DatosContribuyente :initial-data="datosContribuyente" :disabled="false" @change="datosContribuyenteChange"/>
            </v-window-item>

            <v-window-item value="1">
              <IngresosMensuales :initial-data="determinacionTasa" :disabled="false" @change="determinacionTasaChange"/>
            </v-window-item>

            <v-window-item value="2">
              <DeterminacionTasa :initial-data="determinacionTasa" :disabled="false" @change="determinacionTasaChange"/>
            </v-window-item>

            <v-window-item value="3">
              <ConvenioMultilateral :initial-data="convenioMultilateral" :disabled="false" @change="convenioMultilateralChange"/>
            </v-window-item>

            <v-window-item value="4">
              <PagosDirectosTasas :initial-data="pagosDirectosTasas" :disabled="false" @change="pagosDirectosTasasChange"/>
            </v-window-item>

            <v-window-item value="5">
              <DeterminacionDiferencias :initial-data="determinacionDiferencias" :disabled="false" @change="determinacionDiferenciasChange"/>
            </v-window-item>

            <v-window-item value="6">
              <ActividadesDesarrolladas :initial-data="actividadesDesarrolladas" :disabled="false" @change="actividadesDesarrolladasChange"/>
            </v-window-item>

            <v-window-item value="7">
              <SucursalesFiliales :initial-data="sucursalesFiliales" :disabled="false" @change="sucursalesFilialesChange"/>
            </v-window-item>

            <v-window-item value="8">
              <TributoPropiedad :initial-data="tributoPropiedad" :disabled="false" @change="tributoPropiedadChange"/>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
      <div class="botonera">
        <input type="button" value="ANTERIOR" class="boton" v-show="tab>0" @click="anterior">
        <input type="button" value="SIGUIENTE" class="boton" v-show="tab<8" @click="siguiente">
        <input type="button" value="GUARDAR" style="font-weight: bold;" class="boton" @click="grabar(tab,'carga')" v-show="props.usuario.ctacte>0">
        <input type="button" value="PRESENTAR" style="font-weight: bold;" class="boton" @click="grabar(tab, 'confirmacion')" v-show="props.usuario.ctacte>0">
      </div>
    </div>
    <div class="botonera" v-if="vista=='confirmacion'">
      <div class="titulo3">
        <label>El presente formulario reviste carácter de Declaración Jurada</label>
      </div>
      <div class="botonera_center">
        <input type="button" value="CANCELAR" class="boton" @click="cancelPresentation">
        <input type="button" value="PRESENTAR" class="boton" @click="confirmPresentation">
      </div>
    </div>
    <div class="botonera" v-if="vista=='presentacionEnvidada'">
      <div class="titulo3">
        <label>Presentación {{props.ddjj.presentacion == '0' ? 'ORIGINAL' : 'RECTIFICATIVA ' + props.ddjj.presentacion}} de la Declaración Jurada Anual de la Tasa por Inspección de Seguridad e Higiene correspondiente al año {{props.ddjj.periodo}} enviada con fecha {{formattedDate}}</label>
      </div>
      <div class="botonera_center">
        <input type="button" value="IMPRIMIR" class="boton" @click="verVistaImpresion">
      </div>
    </div>
    <div v-if="vista=='impresion'" class="impresion">
      <div class="titulo3">Presentación: {{formattedDate}}</div>
      <div>
      </div>
      <div class="titulo3">Datos del Contribuyente</div>
      <div>
        <DatosContribuyente :initial-data="datosContribuyente" :disabled="true" @change="datosContribuyenteChange"/>
      </div>
      <div class="titulo3">Ingresos Mensuales</div>
      <div>
        <IngresosMensuales :initial-data="determinacionTasa" :disabled="true" @change="determinacionTasaChange"/>
      </div>
      <div class="titulo3">Determinacion de la Tasa</div>
      <div>
        <DeterminacionTasa :initial-data="determinacionTasa" :disabled="true" @change="determinacionTasaChange"/>
      </div>
      <div class="titulo3">Convenio Multilateral</div>
      <div>
        <ConvenioMultilateral :initial-data="convenioMultilateral" :disabled="true" @change="convenioMultilateralChange"/>
      </div>
      <div class="titulo3">Pagos Directos de Tasas</div>
      <div>
        <PagosDirectosTasas :initial-data="pagosDirectosTasas" :disabled="true" @change="pagosDirectosTasasChange"/>
      </div>
      <div class="titulo3">Determinacion de Diferencias</div>
      <div>
        <DeterminacionDiferencias :initial-data="determinacionDiferencias" :disabled="true" @change="determinacionDiferenciasChange"/>
      </div>
      <div class="titulo3">Actividades Desarrolladas</div>
      <div>
        <ActividadesDesarrolladas :initial-data="actividadesDesarrolladas" :disabled="true" @change="actividadesDesarrolladasChange"/>
      </div>
      <div class="titulo3">Sucursales o Filiales</div>
      <div>
        <SucursalesFiliales :initial-data="sucursalesFiliales" :disabled="true" @change="sucursalesFilialesChange"/>
      </div>
      <div class="titulo3">Tributo Municipal por la Propiedad</div>      
      <div>
        <TributoPropiedad :initial-data="tributoPropiedad" :disabled="true" @change="tributoPropiedadChange"/>
      </div>

      <div class="print-button noprint">
        <button @click="printPage" class="boton">Imprimir</button>
        <button v-show="props.usuario.ctacte>0" @click="verMisDDJJ" class="boton">Mis Presentaciones</button>
        <button v-show="props.usuario.ctacte==-1" @click="verMisDDJJ" class="boton">Ver Presentaciones</button>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from "vue";
import { url } from '../config.js';
import axios from 'axios';
import HeaderDDJJ from './ddjj_tabs/HeaderDDJJ.vue'
import DatosContribuyente from './ddjj_tabs/DatosContribuyente.vue'
import DeterminacionTasa from './ddjj_tabs/DeterminacionTasa.vue'
import IngresosMensuales from './ddjj_tabs/IngresosMensuales.vue'
import ConvenioMultilateral from './ddjj_tabs/ConvenioMultilateral.vue'
import PagosDirectosTasas from './ddjj_tabs/PagosDirectosTasas.vue'
import DeterminacionDiferencias from './ddjj_tabs/DeterminacionDiferencias.vue'
import ActividadesDesarrolladas from './ddjj_tabs/ActividadesDesarrolladas.vue'
import SucursalesFiliales from './ddjj_tabs/SucursalesFiliales.vue'
import TributoPropiedad from './ddjj_tabs/TributoPropiedad.vue'
import { headerDummy, datosContribuyenteDummy, determinacionTasaDummy, convenioMultilateralDummy, pagosDirectosTasasDummy, determinacionDiferenciasDummy, actividadesDesarrolladasDummy, sucursalesFilialesDummy, tributoPropiedadDummy } from '../dummy.js'

let vista = ref('carga');
let loading = ref(true);
let error = ref('');
let tab = ref(0);
let cambiosSinGrabar = ref(false);
let header = ref(headerDummy);
let datosContribuyente = ref(datosContribuyenteDummy);
let determinacionTasa = ref(determinacionTasaDummy);
let convenioMultilateral = ref(convenioMultilateralDummy);
let pagosDirectosTasas = ref(pagosDirectosTasasDummy);
let determinacionDiferencias = ref(determinacionDiferenciasDummy);
let actividadesDesarrolladas = ref(actividadesDesarrolladasDummy);
let sucursalesFiliales = ref(sucursalesFilialesDummy);
let tributoPropiedad = ref(tributoPropiedadDummy);
let formattedDate = ref('');

const props = defineProps({
  token: {
    type: String,
    required: true,
  },
  usuario: {
    type: Object,
    required: true,
  },
  ddjj: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['onVerMisDDJJ']);

onMounted(async () => {
  header.value.cc = props.ddjj.cuenta;
  header.value.cuit = props.ddjj.cuit;
  header.value.anio = props.ddjj.periodo;
  header.value.tipo = props.ddjj.presentacion;
  header.value.estado = props.ddjj.estado;
  header.value.razon_social = props.ddjj.razon_social;
  header.value.motivo = props.ddjj.motivo;
  vista.value = props.ddjj.estado == 'Borrador' ? 'carga': 'impresion';
  datosContribuyente.value = JSON.parse(props.ddjj.datos_contribuyente);
  determinacionTasa.value = JSON.parse(props.ddjj.determinacion_tasa);
  convenioMultilateral.value = JSON.parse(props.ddjj.convenio_multilateral);
  convenioMultilateral.value.periodo = props.ddjj.periodo;
  pagosDirectosTasas.value = JSON.parse(props.ddjj.pagos_tasas);
  determinacionDiferencias.value = JSON.parse(props.ddjj.diferencias);
  actividadesDesarrolladas.value = JSON.parse(props.ddjj.actividades);
  sucursalesFiliales.value = JSON.parse(props.ddjj.sucursales_filiales);
  tributoPropiedad.value = JSON.parse(props.ddjj.tributos_propiedad);
  loading.value = false;

  formattedDate.value = props.ddjj.estado == 'Borrador' ? '': formatDate(props.ddjj.fechaCierre);
})

watch(tab, (newValue, oldValue) => {
  console.log('Tab changed from', oldValue, 'to', newValue);
  grabar(oldValue, 'carga');
});

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
  }) + ' hs.';
}

function anterior() {
  tab.value--;
}
function siguiente() {
  tab.value++;
}

function headerChange(header) {
  cambiosSinGrabar.value = true;
  console.log(JSON.stringify(header.value));
}
function datosContribuyenteChange(datosContribuyente) {
  cambiosSinGrabar.value = true;
  console.log(JSON.stringify(datosContribuyente.value));
}
function determinacionTasaChange(determinacionTasa) {
  cambiosSinGrabar.value = true;
  console.log(JSON.stringify(determinacionTasa.value));
}
function convenioMultilateralChange(convenioMultilateral) {
  cambiosSinGrabar.value = true;
  console.log(JSON.stringify(convenioMultilateral.value));
}
function pagosDirectosTasasChange(pagosDirectosTasas) {
  cambiosSinGrabar.value = true;
  console.log(JSON.stringify(pagosDirectosTasas.value));
}
function determinacionDiferenciasChange(determinacionDiferencias) {
  cambiosSinGrabar.value = true;
  console.log(JSON.stringify(determinacionDiferencias.value));
}
function actividadesDesarrolladasChange(actividadesDesarrolladas) {
  cambiosSinGrabar.value = true;
  console.log(JSON.stringify(actividadesDesarrolladas.value));
}
function sucursalesFilialesChange(sucursalesFiliales) {
  cambiosSinGrabar.value = true;
  console.log(JSON.stringify(sucursalesFiliales.value));
}
function tributoPropiedadChange(tributoPropiedad) {
  cambiosSinGrabar.value = true;
  console.log(JSON.stringify(tributoPropiedad.value));
}
async function grabar(paso, v) {
  console.log('Grabar ' + paso);

  error.value = '';
  if (v == 'confirmacion') {
    console.log('confirmacion');

    let datos_contribuyente_req = false;
    let ingresos_mensuales_req = false;
    let determinacion_tasa_req = false;
    let convenioMultilateral_req = false;
    let actividadesDesarrolladas_req = false;
    let tributoPropiedad_req = false;

    const data = datosContribuyente.value;
    if (
      data.domicilio_habilitacion.calle === '' || 
      data.domicilio_habilitacion.numero === '' || 
      data.domicilio_habilitacion.cod_postal === '' || 
      data.domicilio_habilitacion.localidad === '' || 
      data.domicilio_fiscal.calle === '' || 
      data.domicilio_fiscal.numero === '' || 
      data.domicilio_fiscal.cod_postal === '' || 
      data.domicilio_fiscal.localidad === '' || 
      data.domicilio_fiscal.email === '' || 
      data.domicilio_fiscal.telefono === ''
    ) {
      datos_contribuyente_req = true;        
    }

    determinacionTasa.value.tasas_rubros.forEach((c) => {
      if (c.alicuota == null || c.alicuota === '' || c.ingresos == null || c.ingresos === '') {
        ingresos_mensuales_req = true;        
      }
    });
    determinacionTasa.value.tasas.forEach((c) => {
      if (c.deducciones_exenciones == null || c.deducciones_exenciones === '' || c.base_imponible == null || c.base_imponible === '' || c.base_imponible <  0 || c.total_segun_ingresos == null || c.total_segun_ingresos === '' || c.diferencia_hasta_cubrir_el_minimo == null || c.diferencia_hasta_cubrir_el_minimo === '' || c.tasa_determinada == null || c.tasa_determinada === '') {
        console.log(c);
        determinacion_tasa_req = true;
      }
    });

    convenioMultilateral.value.coeficientes_distribucion.forEach((c,i) => {
      if (convenioMultilateral.value.desarrollo_actividad_otras_jurisdicciones=='SI' && i==0 && (c.jurisdiccion == null || c.jurisdiccion == '')) {
        convenioMultilateral_req = true;
      }
      if (c.jurisdiccion != null && c.jurisdiccion != '') {
        if (c.ingresos == null || c.ingresos === '' || c.coeficiente_ingresos == null || c.coeficiente_ingresos === '' || c.gastos == null || c.gastos === '' || c.coeficiente_gastos == null || c.coeficiente_gastos === '' || c.coeficiente_aplicacion == null || c.coeficiente_aplicacion ==='') {
          convenioMultilateral_req = true;
        }
      }
    });
    actividadesDesarrolladas.value.forEach((c,i) => {
      if (i==0 && (c.codigo_de_actividad == null || c.codigo_de_actividad == '')) {
        actividadesDesarrolladas_req = true;
      }

      if (c.codigo_de_actividad != null && c.codigo_de_actividad != '') {
        if (c.descripcion == null || c.descripcion === '' || c.ingresos_brutos_anuales == null || c.ingresos_brutos_anuales === '') {
          actividadesDesarrolladas_req = true;
        }
      }
    });
    tributoPropiedad.value.forEach((c,i) => {
      if (i==0 && (c.calle == null || c.calle == '')) {
        tributoPropiedad_req = true;
      }

      if (c.calle != null && c.calle != '') {
        if (c.numero == null || c.numero === '' || c.localidad == null || c.localidad === '' || c.nro_de_partida == null || c.nro_de_partida === '' || c.caracter == null || c.caracter === '') {
          tributoPropiedad_req = true;
        }
      }
    });

    if (props.ddjj.motivo != 'POR_BAJA') {
      if (datos_contribuyente_req) error.value = "DATOS CONTRIBUYENTE. ";
      if (ingresos_mensuales_req) error.value = error.value + "INGRESOS MENSUALES. ";
      if (determinacion_tasa_req) error.value = error.value + "DETERMINACION DE LA TASA. ";
      if (convenioMultilateral_req) error.value = error.value + "CONVENIO MULTILATERAL. ";
      if (actividadesDesarrolladas_req) error.value = error.value + "ACTIVIDADES DESARROLLADAS. ";
      if (tributoPropiedad_req) error.value = error.value + "TRIBUTO MUNICIPAL POR LA PROPIEDAD. ";
    }

    if (error.value != '') {
      error.value = "Debe completar todos los campos obligatorios de las solapas: " + error.value;
      return;
    }
  }

  loading.value = true;
  error.value = '';
  try {
    const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + props.token
        };
    const payload = { id: props.ddjj.id, paso: paso, estado: 'Borrador', data: getPasoData(paso.toString())};

    await axios.post(url+'/ddjj/grabarPaso', payload, {headers: headers});
    
    loading.value = false;
    vista.value = v;
  } catch(e) {
    loading.value = false;
    error.value = 'Error: ' + e;
  }    
}
function getPasoData(paso) {
  switch (paso) {
      case '0':
        return datosContribuyente.value;
      case '1':
        return determinacionTasa.value;
      case '2':
        return determinacionTasa.value;
      case '3':
        return convenioMultilateral.value;
      case '4':
        return pagosDirectosTasas.value;
      case '5':
        return determinacionDiferencias.value;
      case '6':
        return actividadesDesarrolladas.value;
      case '7':
        return sucursalesFiliales.value;
      case '8':
        return tributoPropiedad.value;
      }
}

function cancelPresentation() {
  vista.value = 'carga';
}
async function confirmPresentation() {
  console.log('confirmPresentation');
  
  loading.value = true;
  error.value = '';
  try {
    const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + props.token
        };
    const payload = { id: props.ddjj.id };

    let res = await axios.post(url + '/ddjj/presentar/' + props.ddjj.id, payload, {headers: headers});

    loading.value = false;
    header.value.estado = 'Presentada';
    
    const fechaCierre = new Date(res.data.fechaCierre);
    formattedDate.value = fechaCierre.toLocaleString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }) + ' hs.';
    vista.value = 'presentacionEnvidada';
    
  } catch(e) {
    loading.value = false;
    error.value = 'Error: ' + e;
  } 

}

function verVistaImpresion() {
  vista.value = 'impresion';
}

function printPage() {
  window.print();
}

function verMisDDJJ() {
  emit('onVerMisDDJJ');
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.custom-window {
  background-color: green; 
  height: 100%; 
  overflow-y: auto;
  padding: 10px;
}
.boton {
  padding: 5px;
  margin: 10px;
  width: 170px;
  background-color: grey;
  color: black;
}
.botonera {
  text-align: right;
  background-color: #DDDDDD;
}
.botonera_center {
  text-align: center;
  background-color: #DDDDDD;
}
.loading {
  display: grid;
  place-items: center;
}
.impresion {
  background-color: #DDDDDD;
}
.titulo3 {
  text-align: center; 
  margin-bottom: 20px;
  font-size: x-large;
  font-weight: bold;
}
.print-button {
  text-align: center;
  margin-top: 20px;
}
@media print {
  .noprint {
    display: none;
  }
}

</style>
