<template>

<v-container fluid class="d-flex justify-center align-center">
    <v-card class="pa-6" max-width="600">
      <v-card-title class="text-center">TASA POR INSPECCION DE SEGURIDAD E HIGIENE</v-card-title>
      
      <!-- Username row -->
      <!-- Username row -->
      <v-row class="mb-4">
        <v-col cols="12">
          <v-row>
            <v-col cols="4">Cuenta Corriente:</v-col>
            <v-col cols="8">
              <input type="text" v-model="user" class="formlogin" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Password row -->
      <v-row class="mb-4">
        <v-col cols="12">
          <v-row>
            <v-col cols="4">Clave:</v-col>
            <v-col cols="8">
              <input :type="inputType" v-model="password" class="formlogin" />
              <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" @click="togglePassword"></i>
            </v-col>
          </v-row>
        </v-col>
      </v-row>      
      <!-- Login button -->
      <v-row>
        <v-col cols="12">
          <v-btn block @click="login">Ingresar</v-btn>
          <div v-if="error!=''" class="alert alert-danger" role="alert">
            {{ error }}
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
      
<script setup>
import { ref, defineEmits, onMounted, computed } from "vue";
import axios from 'axios';
import { url } from '../config.js';

const emit = defineEmits(['loginSuccess']);

const loading = ref(false);
const error = ref("");
const showPassword = ref(false);
let user = "";
let password = "";

onMounted(async () => {
  error.value= "";
})

const inputType = computed(() => showPassword.value ? 'text' : 'password');

const togglePassword = () => {
      showPassword.value = !showPassword.value;
};

async function login() {
  loading.value = true;
  error.value = '';
  try {
      const payload = { username: user, password: password };
      let res = await axios.post(url+'/auth/login', payload);
      loading.value = false;
      let token = res.data.access_token
      emit('loginSuccess', token);
  } catch(e) {
    loading.value = false;
    if (e.response.status === 401) {
          error.value = "Usuario o contraseña inválidos";
      } else {
          // Handle other errors here
          error.value = 'Error: ' + e;
      }
  }    
}

</script>
  
<style>
.formlogin {
  background-color: lightgray;
  border: 1px solid #000;
}
.myspinner {
  position: absolute;
  left: 100%;
  top: 0%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>