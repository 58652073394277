<template>
<div class="main-home">
  <div class="titulo">Tasa por Inspección de Seguridad e Higiene</div>

  <v-container v-show="props.usuario.ctacte>0">
    <v-row class="aviso">
      <div class="titulo2">Nueva declaración jurada</div>
      <!-- First column with 90% width -->
      <v-col cols="10">
        Presentá una nueva declaración jurada anual de la Tasa por Inspección de Seguridad e Higiene. Podés comenzar el trámite y guardar cada paso para completarlo cuando quieras
      </v-col>
      <v-col cols="2">
        <!--input class="boton-home" type="button" value="INICAR"-->
      </v-col>
    </v-row>
  </v-container>

  <v-container v-show="props.usuario.ctacte>0">
    <v-row class="aviso">
      <div class="titulo2">Mis declaraciones juradas presentadas</div>
      <!-- First column with 90% width -->
      <v-col cols="10">
        Consultá tus declaraciones juradas de años anteriores. Podés reimprimir los formularios o rectificar tus declaraciones presentadas
      </v-col>
      <v-col cols="2">
        <!--input class="boton-home" type="button" value="CONSULTAR"-->
      </v-col>
    </v-row>
  </v-container>

  <v-container v-show="props.usuario.ctacte==-1">
    <v-row class="aviso">
      <div class="titulo2">Declaraciones juradas presentadas</div>
      <!-- First column with 90% width -->
      <v-col cols="10">
        Consultá declaraciones juradas de años anteriores. Podés reimprimir los formularios
      </v-col>
      <v-col cols="2">
        <!--input class="boton-home" type="button" value="CONSULTAR"-->
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  usuario: {
    type: Object,
    required: true,
  },
});
</script>

<style>
.main-home {
  display: grid;
  place-items: center;
}
.aviso {
  background-color: white;
  text-align: left;
  margin: 10px;
  padding: 10px;
}
.titulo {
  font-size: x-large;
  color: white;
  margin-top: 8px;
}
.titulo2 {
  font-size: x-large;
}
.boton-home {
  border: 2px solid #000000;
  padding: 8px 16px;
  width: 170px;
}
</style>